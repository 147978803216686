/* Customize your main colors in :root variables */
:root {
  --main-background-color: #171717;
  --card-background-color: #51576D;
  --card-background-lighter-color: #7A7F92;
  --main-text-color:#F7F6F4;
  --title-text-color:#3CBA8B;
}

body{
  background-color:var(--main-background-color);
  font-family: 'Smooch Sans', sans-serif;
  font-size: 1.2em;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}

#menu .wallet-adapter-button {
  background: linear-gradient(#c00e8f, #92106e);
  font-family: 'Lato', sans-serif !important;
}

#main-title {margin: -20px auto 0px auto;}

#big-logo {width: 160px;}
#block-intro {
  text-align: center;
  background-color: transparent !important;
  box-shadow: none;
  margin: 0 auto;
  padding-bottom: 0;
}

#block-intro p {margin: 5px 0 0 0;}

#block-mint {
  text-align: center;
  background-color: transparent !important;
  box-shadow: none;
  margin: 0 auto;
  padding-top: 0;
}

#preview-thugz img {height: 240px;}

#block-mint .wallet-adapter-button {
  background: linear-gradient(#c00e8f, #92106e);
  height: 60px!important;
  width: 175px!important;
  font-family: 'Lato', sans-serif !important;
}
#block-mint .client-sdk-button-trigger-dark {
  background: linear-gradient(#c00e8f, #92106e) !important;
  margin: 0 auto;
  font-family: 'Lato', sans-serif !important;
}


